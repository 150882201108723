var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "confirm-action-dialog",
    {
      attrs: {
        isDialogActive: _vm.isDialogActive,
        title: _vm.title,
        subtitle: _vm.subtitle,
        loading: _vm.isReportRefLoading || _vm.isAttaching,
        rightDisabled: _vm.rightDisabled,
        leftText: _vm.leftText,
        rightText: _vm.rightText,
        fullscreen: _vm.$vuetify.breakpoint.smAndDown,
        rightLoading: _vm.isAttaching
      },
      on: {
        "update:isDialogActive": function updateIsDialogActive($event) {
          _vm.isDialogActive = $event
        },
        "update:is-dialog-active": function updateIsDialogActive($event) {
          _vm.isDialogActive = $event
        },
        close: _vm.close,
        leftClick: _vm.leftClick,
        rightClick: _vm.rightClick
      }
    },
    [
      _c(
        "v-window",
        {
          ref: "window",
          attrs: {
            touchless: ""
          },
          model: {
            value: _vm.step,
            callback: function callback($$v) {
              _vm.step = $$v
            },
            expression: "step"
          }
        },
        [
          _c(
            "v-window-item",
            {
              attrs: {
                value: 0
              }
            },
            [
              _c("refs-selection-by-type", {
                ref: "reportSelection",
                attrs: {
                  partnerId: _vm.value.partnerId,
                  refType: _vm.REPORT,
                  showAsMenu: false,
                  small: false,
                  height:
                    _vm.$vuetify.breakpoint.smAndDown && _vm.containerHeight
                      ? _vm.containerHeight + "px"
                      : ""
                },
                on: {
                  select: _vm.addReportToRefs
                }
              })
            ],
            1
          ),
          _c(
            "v-window-item",
            {
              attrs: {
                value: 1
              }
            },
            [
              _c("v-subheader", [
                _vm._v(" " + _vm._s(_vm.$t("message.selectedReport")) + " ")
              ]),
              _c(
                "v-card",
                {
                  attrs: {
                    outlined: ""
                  }
                },
                [
                  _c("refs-report", {
                    attrs: {
                      item: _vm.selectedReport
                    }
                  })
                ],
                1
              ),
              _c(
                "v-subheader",
                [
                  _vm._v(" " + _vm._s(_vm.$t("message.attachments")) + " "),
                  _c("v-spacer"),
                  _vm.selectedAttachments.length ===
                  _vm.imageOrPdfAttachments.length
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            "x-small": "",
                            elevation: 0
                          },
                          on: {
                            click: function click() {
                              return _vm.selectedAttachments.splice(0)
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("message.unselectAll")) + " "
                          )
                        ]
                      )
                    : _c(
                        "v-btn",
                        {
                          attrs: {
                            "x-small": "",
                            elevation: 0
                          },
                          on: {
                            click: _vm.selectAllAttachments
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("message.selectAll")) + " "
                          )
                        ]
                      )
                ],
                1
              ),
              _vm.imageOrPdfAttachments.length
                ? _c(
                    "div",
                    _vm._l(_vm.imageOrPdfAttachments, function(
                      attachment,
                      index
                    ) {
                      return _c("partner-message-detail-attachment-card", {
                        key:
                          "partner-message-detail-attachment-card" +
                          attachment.name +
                          index +
                          "attach-dialog",
                        attrs: {
                          value: attachment,
                          message: _vm.value,
                          hideActions: true
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item-action",
                              fn: function fn() {
                                return [
                                  _c("v-checkbox", {
                                    attrs: {
                                      value: index
                                    },
                                    on: {
                                      click: function click($event) {
                                        $event.stopPropagation()
                                      }
                                    },
                                    model: {
                                      value: _vm.selectedAttachments,
                                      callback: function callback($$v) {
                                        _vm.selectedAttachments = $$v
                                      },
                                      expression: "selectedAttachments"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      })
                    }),
                    1
                  )
                : _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("message.noAttachments")) +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          ),
          _c(
            "v-window-item",
            {
              attrs: {
                value: 2
              }
            },
            [
              _c("v-img", {
                attrs: {
                  align: "center",
                  justify: "center",
                  contain: "",
                  "max-height": "250px",
                  src: require("@/assets/undraw_done_re_oak4.svg")
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }