















































































import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import RefsSelect from "@/components/utility/RefsSelect.vue";
import RefsSelected from "@/components/utility/RefsSelected.vue";
import { IDialog } from "@/lib/interfaces/dialog.interface";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IPartnerMessage } from "@/models/partner-message.entity";
import { BackendResourceEnum, ResourceEnum } from "@/store/enum/authResourceEnum";
import { Component, Prop, Ref } from "vue-property-decorator";
import RefsSelectionByType from "@/components/utility/RefsSelectionByType.vue";
import { IReport } from "@/models/report.entity";
import { handleError } from "@/lib/utility/handleError";
import RefsReport from "../utility/RefsReport.vue";
import PartnerMessageDetailAttachmentCard from "./PartnerMessageDetailAttachmentCard.vue";
import { IPartnerMessageAttachment } from "@/models/partner-message-attachment.entity";
import { IPartnerImage } from "@/models/partner-image.entity";
import { IBaseImage } from "@/models/caseEntity";
import { ISignDocument } from "@/models/sign-document.entity";
import { IReference, Reference } from "@/models/reference.entity";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { $t } from "@/lib/utility/t";
import { ProgressStatusEnum } from "@/store/enum/partner/progress.status.enum";

@Component({
  name: "PartnerMessageDetailAttachDialog",
  components: {
    ConfirmActionDialog,
    RefsSelected,
    RefsSelectionByType,
    RefsSelect,
    RefsReport,
    PartnerMessageDetailAttachmentCard
  }
})
export default class PartnerMessageDetailAttachDialog extends PermissionMixin implements IDialog {
  readonly REPORT = BackendResourceEnum.REPORT;

  @Ref("reportSelection")
  reportSelection!: RefsSelectionByType<IReport>;

  @Prop()
  value!: IPartnerMessage;

  isDialogActive = false;

  isReportRefLoading = false;

  selectedReport: IReport | null = null;

  step = 0;

  selectedAttachments: number[] = [];

  isAttaching = false;

  get containerHeight() {
    return (this.$refs.window as Element)?.clientHeight;
  }

  get title() {
    switch (this.step) {
      case 0:
        return $t("message.linkMailToReport");
      case 1:
        return $t("message.addAttachmentsToReport");
      case 2:
        return $t("message.finished");
      default:
        return "";
    }
  }

  get subtitle() {
    switch (this.step) {
      case 0:
        return $t("message.selectReport");
      case 1:
        return $t("message.addAttachmentsToReportSubtitle");
      case 2:
        return "";
      default:
        return "";
    }
  }

  get rightDisabled() {
    switch (this.step) {
      case 0:
        return !this.selectedReport;
      case 1:
        return !this.selectedAttachments.length;
      default:
        return false;
    }
  }

  get leftText() {
    switch (this.step) {
      case 0:
        return $t("message.close");
      case 1:
        return $t("message.back");
      case 2:
        return $t("message.close");
      default:
        return "";
    }
  }

  get rightText() {
    switch (this.step) {
      case 0:
        return $t("message.next");
      case 1:
        return $t("message.next");
      case 2:
        return $t("message.attachMore");
      default:
        return "";
    }
  }

  get reportReferences(): IReference[] {
    return this.value?.refs?.filter(r => r.refType === ResourceEnum.REPORT) ?? [];
  }

  selectAllAttachments() {
    this.selectedAttachments.splice(0);
    this.selectedAttachments.push(...new Array(this.imageOrPdfAttachments.length).fill(0).map((_, i) => i));
  }

  async addReportToRefs(selected: IReport) {
    this.isReportRefLoading = true;
    const newRefs = [...(this.value.refs ?? [])];
    let isAlreadyReferenced = true;
    if (!newRefs.find(r => r.refId === selected.id)) {
      isAlreadyReferenced = false;
      newRefs.unshift({
        refId: selected.id,
        refType: ResourceEnum.REPORT
      });
    }
    if (!isAlreadyReferenced) {
      await this.value
        .updatePartial({
          refs: newRefs
        })
        .catch(handleError);
    }
    this.selectReport(selected);
    this.isReportRefLoading = false;
  }

  selectReport(selected: IReport) {
    this.selectedReport = selected;
    this.rightClick();
  }

  isLoading = false;

  async rightClick() {
    if (this.step === 0) {
      this.step++;

      if (!this.imageOrPdfAttachments.length) {
        this.close();
      }

      return;
    }

    if (this.step === 1) {
      this.isAttaching = true;
      await this.attach()
        .then(() => this.close())
        .catch(handleError);
      this.isAttaching = false;

      return;
    }

    if (this.step === 2) {
      this.selectedReport = null;
      this.step = 0;

      return;
    }
  }

  leftClick() {
    if (this.step === 0) {
      this.close();

      return;
    }

    if (this.step === 1) {
      this.selectedReport = null;
      this.step--;

      return;
    }

    if (this.step === 2) {
      this.close();

      return;
    }

    this.step--;
  }

  get imageOrPdfAttachments() {
    return this.value.attachments.filter(v => v.isPdf || v.isImage);
  }

  async attach() {
    const files = this.selectedAttachments.map(index => this.imageOrPdfAttachments[index]);
    const imageAttachments = files.filter(file => file?.isImage) as IPartnerMessageAttachment[];
    const pdfAttachments = files.filter(file => file?.isPdf) as IPartnerMessageAttachment[];
    const report = this.selectedReport;
    if (!report) return;

    await Promise.all([
      this.attachImagesToReport(imageAttachments, report),
      this.attachPdfsToReport(pdfAttachments, report)
    ]);
  }

  private async attachImagesToReport(imageAttachments: IPartnerMessageAttachment[], report: IReport) {
    if (!imageAttachments.length) return;
    if (!report) return;

    const imagesPopulated = (await Promise.all(
      imageAttachments
        .map(async attachment => {
          if (!attachment.populatedReference) await attachment.populateReference(this.value);
          if (attachment.populatedReference) return attachment.populatedReference as IPartnerImage;
        })
        .filter(i => !!i)
    )) as IPartnerImage[];

    const reportImageIds = Object.values(report.images)
      .map(images => images.map((image: IBaseImage) => image.id as string))
      .flat() as string[];

    const allImages = [...reportImageIds, ...imagesPopulated.map(image => image.id)];

    await report.updateReportImages({ imageIds: allImages });
  }

  private async attachPdfsToReport(documentAttachments: IPartnerMessageAttachment[], report: IReport) {
    if (!documentAttachments.length) return;
    if (!report) return;
    if (!this.value) return;

    const documents = (
      await Promise.all(
        documentAttachments.map(async attachment => {
          if (!attachment.populatedReference) await attachment.populateReference(this.value);
          if (attachment.populatedReference) return attachment.populatedReference as ISignDocument;
        })
      )
    ).filter(document => !!document) as ISignDocument[];

    const documentsAsync = documents.map(document => this.addReportToDocumentRefs(document, report).catch(handleError));
    await Promise.all(documentsAsync);
  }

  private async addReportToDocumentRefs(document: ISignDocument, report: IReport) {
    if (!this.value) return;
    if (!document) return;

    // check if the document already has a ref to the reports, if not add it
    const newRefsForDocument: IReference[] = [];
    if (!document.refs.find(ref => ref.refId === report.id)) {
      newRefsForDocument.push(new Reference({ refId: report.id, refType: ResourceEnum.REPORT }));
    }

    // check if the document already has a ref to the message, if not add it
    if (!document.refs.find(ref => ref.refId === this.value?.id)) {
      newRefsForDocument.push(new Reference({ refId: this.value.id, refType: ResourceEnum.MESSAGE }));
    }

    // update the document with the new refs if there are any
    if (newRefsForDocument.length) {
      document.refs.push(...newRefsForDocument);
      await document.update();
    }
  }

  async open(): Promise<void> {
    this.selectAllAttachments();
    this.selectedReport = null;
    this.step = 0;
    this.isDialogActive = true;
    this.$nextTick(async () => {
      const reportSelection = this.$refs.reportSelection as RefsSelectionByType<IReport>;

      reportSelection?.setFilter([
        new PageFilterElement({
          key: "customerContact.email",
          value: this.value.from,
          operation: PageFilterOperationEnum.EQUAL
        }),
        new PageFilterElement({
          key: "progressStatus",
          value: ProgressStatusEnum.FINISHED,
          operation: PageFilterOperationEnum.NOT_EQUAL
        })
      ]);

      await reportSelection?.debounceFetchFirstPage({ partnerId: this.value.partnerId });
    });
  }

  async openForReport(report: IReport): Promise<void> {
    this.selectAllAttachments();
    this.selectedReport = report;
    this.step = 1;
    this.isDialogActive = true;
  }

  async openForAttachment(attachment: IPartnerMessageAttachment): Promise<void> {
    this.selectedAttachments.splice(0);
    const index = this.imageOrPdfAttachments.findIndex((a, i) => {
      if (a.name === attachment.name && a.size === attachment.size) {
        this.selectedAttachments.push(i);
        return true;
      }
      return false;
    });
    if (index > -1) this.selectedAttachments.push(index);
    this.step = 0;
    this.isDialogActive = true;
  }

  close(): void {
    this.isDialogActive = false;
  }
}
